.content-sidebar{
    display: inline-flex;
    width: 220px;
    top: 0;
    left: 0;
    bottom: 0;
    position: fixed;
    background-color: #222d32;
    align-content: space-between;
    border-radius: 0px;
    margin-bottom: 0px;    
    max-height: calc(100vh - 0em);
    .nav{
        &.plan-navigation{
            display: flex;
            justify-content: flex-start;
            padding-top: 55px;
            margin: 0px;
            padding-right: 0px !important;
            align-items: center;
        }
        &.bottomLinks{
            bottom: 0px;
            position:relative !important;
        }
        &-item {   
            position: relative;
            width: 100%;
            white-space: nowrap;            
            letter-spacing: .025em;
            font-weight: 400;
            .nav-subitem {   
                padding:0em;
                .nav-link{
                    color: #b8c7ce;
                    font-size: 14px;
                    padding:8px 20px 8px 38px; 
                    &:hover, &:focus{
                        color:#fff;
                    }                   
                }
                &.current{
                    .nav-link{
                    color: #fff;
                    }
                }                
            }       

            .nav-dropdown{
                color: #b8c7ce;
                font-size: 14px;
                padding: 12px 5px 12px 15px;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                line-height: 21px;
                align-content: center;
                flex-direction: row;
               
                em {
                    color: #b8c7ce;
                    font-size: 16px;
                    width: 23px;
                    display: inline-block;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;                 
                   
                }
                &:hover, &:focus{
                    color: #fff;                  
                }
            }
            .nav-dropdown:hover em{
                color: #fff;
            }
            .dropdown-toggle::after{
                display: none;
            }
          }
          .active {
              .nav-dropdown{
                color: #fff;
                line-height: 22px;
              } 
        } 
          .nav-collapse{
            background-color: #2c3b41;          
                    &.admin-menu-section{
                        max-height: calc(100vh - 25em);
                    }
        }
    }   
}
.sidebar .nav>li>.nav-item, .sidebar .nav>li>a {
    color: #b8c7ce;
    font-size: 14px;
}
