@import "../../variable.scss";
.footer{
    display: flex;
    align-items: center;
    background-color: var(--theme-background);
    height: 42px;
    padding: 13px;
    width: 100%;
    position: fixed;
    left: 220px;
    bottom: 0;
    z-index: 999;
    &-text{
        color:$color-white;
        font-size: 12px;
        line-height: 1.7;
    }
}