:root {
  --theme-background: #2578a2;

  --button-primary-hover: #125272;
  --button-secondary: #464646;
  --button-secondary-hover: #222;
  --button-danger: #dd4b39;
  --button-danger-hover: #ac2925;
  --button-warning: #ff902b;
  --button-warning-hover: #fa8212;
  --button-success: #00a65a;
  --button-success-hover: #008d4c;

  --button-start: #afafaf;
  --button-start-hover: #9b9b9b;
  --button-gray: #e6e6e6;
  --button-gray-hover: #bbbaba;
  --button-yellow: #e6de0d;
  --button-yellow-hover: #c1bb09;

  --button-disabled: #f5f5f5;
  --border-disabled: #dcdcdc;

  // Global Primary Text color css
  --primary-text-color: #2a2a2a;
  --secondary-text-color: #656565;
  --dark-text-color: #373737;

  // Choose package plans color css
  --color-info: #00c0ef;
  --color-info-hover: #01b1dc;
  --color-info-light: #0bd8c9;
  --color-info-light-hover: #13bbaf;
  --color-vivid-orange: #f39c12;
  --color-vivid-orange-hover: #e08e0b;

  // Global panel box variables css
  --panel-default: #f5f5f5;
  --panel-primary: #2578a2;
  --panel-body-boder: #d5d5d5;

  // Global input variables css
  --input-border: #ddd;
  --placeholder-color: #b8b8b8;
  --input-color: #8a8b8c;

  // Global table ode & even variables css
}
// Global variables css
$border-radius: 3px;
$font-bold: 700;
$globle-large: 1.1em;
$globle-text: 0.76rem;
$globle-text-second: 0.87rem;

// Global variables taxt color css
$color-white: #ffffff;
$color-black: #000000;
$color-alert: #ff0000;
$color-light-grey: #e6e6e6;
$color-grey: #cccccc;
$color-dark-light: #ddd;
$color-dark-grey: #cfdbe2;
$color-primary: #2578a2;
$color-secondary: #222;

