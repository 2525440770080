@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,500i,700");
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
// @import "bootstrap/dist/css/bootstrap.css";
// @import "font-awesome/css/font-awesome.css";

//for ng select theme
@import "@ng-select/ng-select/themes/default.theme.css";
// @import "@ng-select/ng-select/themes/material.theme.css";

// @import "angular-notifier/styles";
@import '../node_modules/angular-notifier/styles';

@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@font-face {
  font-family: "robotobold";
  src: url("./assets/font/roboto-bold-webfont.woff2") format("woff2"),
    url("./assets/font/roboto-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotomedium";
  src: url("./assets/font/roboto-medium-webfont.woff2") format("woff2"),
    url("./assets/font/roboto-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotoregular";
  src: url("./assets/font/roboto-regular-webfont.woff2") format("woff2"),
    url("./assets/font/roboto-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* You can add global styles to this file, and also import other style files */
@import "@ng-select/ng-select/themes/default.theme.css";
@import "../src/assets/custom-style/sidebar-navigation.scss";
@import "../src/assets/custom-style/footer-style.scss";
@import "../src/assets/custom-style/header-style.scss";
@import "../src/assets/custom-style/home-style.scss";

@import "../src/variable.scss";

/*======= Style the Body reset Property ========*/
* {
  box-sizing: border-box;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  // font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a,
button {
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
a:not([href]):not([class]) {
  color: #5d9cec;
  text-decoration: none;
  &:hover,
  &:focus {
    color: #1b72e2;
    text-decoration: none;
  }
}
a {
  color: #5d9cec;
  text-decoration: none;
  &:hover,
  &:focus {
    color: #1b72e2;
    text-decoration: none;
  }
}

// a:not([href]):not([tabindex]) {
//   color: $secondaryTextColor;
// }

textarea,
input,
button:focus {
  outline: none;
  box-shadow: none;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1px;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.7;
}
body {
  font-family: Arial, sans-serif;
  background-color: #f5f7fa;
  font-weight: 400;
  line-height: 1;
  margin: 0;
  padding: 0;
  font-size: $globle-text-second;
  color: var(--secondary-text-color);
}
/*======= Style the img responsive ========*/
img {
  &.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
    width: auto;
  }
}

/*======= Style the customize scrollbar bar ========*/
::-webkit-scrollbar {
  width: 5px;
  background: #eee;
  width: 5px;
}
::-webkit-scrollbar:horizontal {
  height: 5px;
}
::-webkit-scrollbar-track {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb {
  background: var(--theme-background);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--theme-background);
}
::-webkit-scrollbar-thumb:active {
  background-color: var(--theme-background);
}
body::-webkit-scrollbar {
  width: 5px;
}
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
body::-webkit-scrollbar-thumb {
  background: var(--theme-background);
  outline: 1px solid rgba(239, 246, 253, 0.75);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: var(--theme-background);
}

// ------Style the Custom checkbox
.checkbox-input {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    padding: 0;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    .checkbox-text {
      font-size: $globle-text;
    }
  }

  // Box.
  & + label:before {
    content: "";
    margin-right: 6px;
    display: inline-block;
    vertical-align: text-top;
    width: 14px;
    height: 14px;
    background: $color-white;
    border: 1px solid $color-grey;
    border-radius: $border-radius;
  }

  // Box hover
  &:hover + label:before {
    background: transparent;
  }

  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.034);
  }

  // Box checked
  &:checked + label:before {
    background: var(--theme-background);
    border: 1px solid transparent;
  }

  // Disabled state label.
  &:disabled + label {
    color: var(--placeholder-color);
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: $color-dark-light;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: "";
    position: absolute;
    left: 2px;
    top: 8px;
    background: $color-white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 $color-white, 4px 0 0 $color-white,
      4px -2px 0 $color-white, 4px -4px 0 $color-white, 4px -6px 0 $color-white,
      4px -8px 0 $color-white;
    transform: rotate(45deg);
  }
}
// ------ End Custom checkbox styles

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 16px;
  height: 16px;
  border: 1px solid $color-dark-light;
  border-radius: 100%;
  background: $color-white;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: var(--theme-background);
  position: absolute;
  top: 5px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
// ------ End Custom radio styles

// ---- Style the top header navigation bar
.header-navbar {
  display: flex;
  background-color: var(--theme-background);
  min-height: 55px;
  max-height: 58px;
  width: 100%;
  position: relative;
  justify-content: space-between;
  & > .nav-header {
    display: inline;
    padding: 0px 10px;
    max-height: 55px;
    & > .nav-title {
      display: inline-block;
      padding: 0;
      a {
        &.navbar-logo {
          padding: 0;
          // img {
          //   &.img-responsive {
          //     width: 15.6em;
          //   }
          // }
        }
      }
    }
  }
  & > .nav-btn {
    display: none;
  }
  & > .nav-links {
    display: inline;
    float: right;
    & > a {
      display: inline-block;
      padding: 13px 10px 13px 10px;
      text-decoration: none;
      color: #efefef;
      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
  }
  & > #nav-check {
    display: none;
  }
}

@media (max-width: 778px) {
  .top-navbar {
    & > .nav-btn {
      display: inline-block;
      position: absolute;
      right: 0px;
      top: 0px;
      & > label {
        display: inline-block;
        width: 50px;
        height: 50px;
        padding: 13px;
        & > span {
          display: block;
          width: 25px;
          height: 10px;
          border-top: 2px solid #eee;
        }
      }
    }
    & > .nav-links {
      position: absolute;
      display: block;
      width: 100%;
      height: 0px;
      transition: all 0.3s ease-in;
      overflow-y: hidden;
      top: 50px;
      left: 0px;
      & > a {
        display: block;
        width: 100%;
      }
    }
    & > #nav-check {
      &:not(:checked) {
        & ~ .nav-links {
          height: 0px;
        }
      }
      &:checked {
        & ~ .nav-links {
          height: calc(100vh - 50px);
          overflow-y: auto;
        }
      }
    }
  }
  .top-navbar > .nav-btn > label:hover,
  .top-navbar #nav-check:checked ~ .nav-btn > label {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

//--- Style the form field
//--------------
input,
select {
  &.form-input {
    &::placeholder {
      color: #b7bec7;
      font-size: 0.92em;
    }
  }
}
.form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.form-group {
  display: inline-block;
  position: relative;
  text-align: left;
  width: 100%;
  max-width: 100%;
  // margin-bottom: 1em;
  .label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    line-height: 1em;
    color: var(--secondary-text-color);
    font-size: $globle-text;
    padding: 0em;
    font-weight: 600;
    &.label-text {
      color: var(--secondary-text-color);
      font-weight: 500;
    }
  }

  .text-forgot-test {
    margin: 10px 0px 5px;
    a {
      font-size: $globle-text;
    }
  }
  &.panelgroup {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
  }
  .form-panel {
    width: 100%;
    padding: 0em 1.2em;
    &.input-label {
      margin-bottom: 0.24rem;
      font-weight: $font-bold;
    }
    &:not(:first-child) {
      padding-right: 0rem;
    }
    &:not(:last-child) {
      padding-left: 0rem;
    }
    &:not(:nth-of-type(0)) {
      padding: 0rem;
    }
    .control-label {
      margin-bottom: 0.24rem;
      font-weight: $font-bold;
    }
    .controls {
      margin-top: 0.8em;
    }
  }
  &.radio-button-field {
    margin: 1em 0em 2em;
  }
  .validation-message {
    color: $color-alert;
    margin-top: 0.2em;
    font-size: 0.7rem;
  }
}
.input-element {
  outline: none;
  box-shadow: none;
  color: var(--input-color);
  flex: auto;
  height: 38px;
  background: $color-white;
  border: 1px solid var(--input-border);
  font-size: $globle-text-second;
  border-radius: $border-radius;
  max-width: 100%;
  position: relative;
  padding: 8px 8px;
  width: 100%;
  &::placeholder {
    color: var(--placeholder-color);
    font-size: $globle-text-second;
  }

  &.editable-input {
    background-color: #fbfbfb;
    border: 1px solid #ddd;
    font-size: 13px;
  }
  &.select-input {
    font-size: 12px;
    padding: 5px 10px;
    color: var(--placeholder-color);
    height: 2.6em;
    &.showentries-select {
      height: 2.7em;
      font-size: $globle-text-second;
    }
  }

  &.select-dropdown-menu {
    padding: 0px;
    height: auto;
    min-height: 38px;
    .flags {
      max-width: 23px;
      margin-right: 0.4em;
    }
    .ng-arrow-wrapper {
      .ng-arrow {
        border-width: 4px 4px 2.5px;
      }
    }
    &.ng-select-opened > .ng-select-container .ng-arrow {
      border-width: 0 4px 4px;
    }
    &.ng-select-opened {
      border: 1px solid var(--theme-background);
    }
    &.ng-select-opened > .ng-select-container {
      box-shadow: none;
    }
    &.ng-select-opened
      > .ng-select-container
      .ng-value-container
      .ng-placeholder {
      display: none !important;
    }
    &.ng-untouched > .ng-select-container .ng-value-container .ng-placeholder {
      // display: none !important;
    }

    .ng-dropdown-panel {
      &-items {
        .ng-option {
          font-size: $globle-text;
        }
      }
    }
    .ng-select-container {
      background-color: #fbfbfb;
      .ng-value-container {
        .ng-placeholder {
          color: var(--placeholder-color);
          font-size: $globle-text;
          top: 9px;
        }
      }
      .ng-value-label {
        font-size: $globle-text;
      }
    }

    &.select-year-dorpdown {
      .ng-value-container {
        .ng-input {
          opacity: 0 !important;
        }
      }
    }

    &.overflow-dropdown {
      display: table-cell;
    }

    &.ng-select-dropdown {
      .ng-select-container {
        .ng-value-container {
          .ng-placeholder {
            display: block;
          }
        }
        .ng-value-label {
          font-size: $globle-text;
        }
      }
      &.ng-dirty {
        .ng-select-container {
          .ng-value-container {
            .ng-placeholder {
              display: none !important;
            }
          }
        }
      }
    }
    &.field-disabled {
      .ng-select-container {
        background-color: #edf1f2 !important;
        cursor: not-allowed;
      }
    }

    &.placeholder-section {
      .ng-select-container {
        background-color: #fbfbfb;
        .ng-value-container {
          .ng-placeholder {
            color: var(--placeholder-color);
            font-size: $globle-text;
            top: 12px;
          }
        }
        .ng-value-label {
          font-size: $globle-text;
        }
      }
    }
    &.edit-profile-select  {
      .ng-select-container {
        height: 38px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &.ng-select-opened > {
        .ng-dropdown-panel {
          width: 234px !important;
        }
      }
   
    }
    &.year-dropdown{
      min-height: 30px;
      .ng-select-container{
        height: 30px;
        min-height: 30px;
      }
    }
  }

  &.select-dropdown-menu {
    &.user-align-dropdown {
      .ng-select-container {
        background-color: #fbfbfb;
        .ng-value-container {
          .ng-placeholder {
            color: var(--placeholder-color);
            font-size: $globle-text;
            top: 12px;
          }
        }
        .ng-value-label {
          font-size: $globle-text;
        }
      }
    }


    &.edit-select {
      min-height: 32px;
      height: 32px;
      .ng-select-container {
        min-height: 30px;
        height: 30px;
      }
    }
  }


  &.input-group-select {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}


.filtar {
  justify-content: flex-end;
  &-dropdwon {
    min-width: 35%;
  }
}
.form-group .input-element:focus,
.form-group .input-element:hover {
  border: 1px solid var(--theme-background);
}
.form-group .input-element.is-invalid {
  border: 1px solid $color-alert;
}

.form-control-label .checkbox-label-text {
  .label-text {
    margin-bottom: 1em;
  }
}

// style of the text editor
.text-editor {
  &.editor {
    .angular-editor {
      &-toolbar {
        background-color: transparent !important;
        border: none !important;
        padding: 0.5em 0 0 !important;
      }
    }
  }
  .angular-editor-textarea {
    border-radius: $border-radius;
    &.angular-editor-textarea:focus {
      border: 1px solid var(--theme-background);
      outline: 0px solid var(--theme-background);
      box-shadow: none;
      border-radius: $border-radius;
    }
  }
}

// ------Style the Button
.button {
  height: 36px;
  font-weight: 400;
  box-shadow: none;
  outline: none;
  padding: 8px 10px;
  color: $color-white;
  border-radius: $border-radius;
  border-color: transparent;
  transition: all 0.4s ease-in-out 0s;
  justify-content: center;
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    color: $color-white;
    text-decoration: none;
  }
  &.button-primary {
    background: var(--theme-background);
    &:hover,
    &:focus {
      background: var(--button-primary-hover);
      transition: all 0.2s ease-in-out 0s;
    }
  }
  &.button-outline-primary {
    color: var(--theme-background);
    border: 1px solid var(--theme-background);
    &:hover,
    &:focus {
      color: $color-white;
      border: 1px solid var(--theme-background);
      background: var(--button-primary-hover);
      transition: all 0.2s ease-in-out 0s;
    }
  }
  &.button-secondary {
    background: var(--button-secondary);
    &:hover,
    &:focus {
      background: var(--button-secondary-hover);
      transition: all 0.2s ease-in-out 0s;
    }
  }
  &.button-danger {
    background: var(--button-danger);
    &:hover,
    &:focus {
      background: var(--button-danger-hover);
      transition: all 0.2s ease-in-out 0s;
    }
  }
  &.button-success {
    background: var(--button-success);
    &:hover,
    &:focus {
      background: var(--button-success-hover);
      transition: all 0.2s ease-in-out 0s;
    }
  }
  &.button-default {
    background: transparent;
    border: 1px solid #dcdcdc;
    color: var(--secondary-text-color);
    padding: 8px 8px;
    &:hover,
    &:focus {
      background-color: #f5f5f5;
      transition: all 0.2s ease-in-out 0s;
    }
  }
  &.button-start {
    background: var(--button-start);
    &:hover,
    &:focus {
      background: var(--button-start-hover);
    }
  }
  &.button-gray {
    background: var(--button-gray);
    border: 1px solid var(--button-gray-hover);
    color: #333;
    &:hover,
    &:focus {
      background: var(--button-gray-hover);
      color: #333;
      border: 1px solid var(--button-gray-hover);
    }
  }

  &.button-yellow {
    background: var(--button-yellow);
    border: 1px solid var(--button-yellow-hover);
    color: #333;
    &:hover,
    &:focus {
      background: var(--button-yellow-hover);
      color: #333;
      border: 1px solid var(--button-yellow-hover);
    }
  }

  &:disabled {
    background: var(--button-disabled);
    border: 1px solid var(--border-disabled);
    color: var(--secondary-text-color);
    &:hover,
    &:focus {
      background: var(--button-disabled);
      transition: all 0.2s ease-in-out 0s;
    }
  }

  &.button-warning {
    background: var(--button-warning);
    padding: 8px 8px;
    // font-size: 0.76rem;
    &:hover,
    &:focus {
      background: var(--button-warning-hover);
      transition: all 0.2s ease-in-out 0s;
    }
  }
  &.button-info {
    background: #55ba35;
    cursor: pointer;
    &:hover,
    &:focus {
      background: rgb(63, 168, 31);
      transition: all 0.2s ease-in-out 0s;
    }
  }

  &.disabled {
    background: var(--button-disabled);
    border: 1px solid var(--border-disabled);
    color: var(--secondary-text-color);
    &:hover,
    &:focus {
      background: var(--button-disabled);
      transition: all 0.2s ease-in-out 0s;
    }
  }

  &.btn-group {
    background-color: #fbfbfb;
    border: 1px solid #ced4da;
    em.fa {
      color: #333;
      font-size: $globle-text;
    }
    &:hover,
    &:focus {
      background-color: #f5f5f5;
      outline: none;
      box-shadow: none;
    }
  }
  &.button-white {
    color: $color-white;
  }
}

.dark_grey:disabled {
  color: #dfdfdf;
}
.button-success {
  height: 36px;
  font-weight: 400;
  box-shadow: none;
  outline: none;
  padding: 8px 10px;
  color: $color-white;
  border-radius: $border-radius;
  border-color: transparent;
  transition: all 0.4s ease-in-out 0s;
  justify-content: center;
  display: flex;
  align-items: center;
  background: var(--button-success);
  &:hover,
  &:focus {
    background: var(--button-success-hover);
    transition: all 0.2s ease-in-out 0s;
  }
}
.strong-text {
  font-weight: $font-bold;
}

button[disabled],
html input[disabled] {
  background: var(--button-disabled);
  border: 1px solid var(--border-disabled);
  color: var(--secondary-text-color);
  &:hover,
  &:focus {
    background: var(--button-disabled);
    transition: all 0.2s ease-in-out 0s;
  }
}

// ------ Style of the table panel
.panel {
  display: flex;
  flex-direction: column;
  margin-bottom: 21px;
  background-color: $color-white;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  &-table {
    width: 100%;
    margin-bottom: 60px;
    border: 1px solid var(--panel-body-boder);
  }
  &-package {
    width: 42em;
    border: 1px solid var(--panel-body-boder);
  }
  &-primary {
    border-top: 4px solid var(--theme-background);
  }
  &-primary > &-heading {
    background-color: transparent;
    border-color: var(--panel-body-boder);
    background-image: none;
    border-bottom: 1px solid var(--panel-body-boder);
    margin-bottom: 1%;
    padding: 0.8em 1.3em;
  }
  &-title {
    color: #737373;
    font-size:14px;
    font-weight: $font-bold;
  }
  .table-title {
    padding-top: 0.2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &-body {
    padding: 0em 1.3em;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &-footer {
    padding: 0em;
    background: none;
    border: none;
    &.panel-footer-box {
      border-top: 1px solid var(--border-disabled);
    }
  }

  .collapsible {
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    &-heading {
      border-bottom: 1px solid #dcdcdc;
      padding: 9px 10px 8px;
      margin-bottom: 5px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    &-section {
      background: $color-white;
      .collapsible-body {
        background: $color-white;
      }
    }
    &-section:nth-child(odd) {
      background: #eef2f5;
    }
  }

  // ------ Style of the Table refresh
  .refresh {
    list-style: none;
    display: flex;
    padding: 0px 0px;
    justify-content: flex-end;
    align-items: center;
    &-icon {
      list-style: none;
      float: right;
      padding: 0px 8px;
      .refresh-link {
        text-decoration: none;
        padding: 3px 0px;
        color: var(--dark-text-color);
        display: flex;
        align-items: center;
        i.fa {
          text-decoration: none;
          padding: 3px 0px;
          color: var(--dark-text-color);
          font-size: 13px;
        }
      }
    }
  }
}
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba($color: #171725, $alpha: 0.5);
  z-index: 999;
}
.popup {
  margin-left: 35px;
  width: 55em;
  max-width: 80%;
  padding: 0rem;
  border-radius: 4px;
  position: fixed;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  z-index: 9999;
  .panel-title {
    appearance: none;
    margin: 0px;
    .fa {
      font-size: 18px;
    }
  }
  &.panel-container {
    .panel {
      box-shadow: none;
      margin-bottom: 0px;
    }
  }
  .popup-body {
    padding: 0.3rem 1rem 1rem;
    max-height: calc(100vh - 15em);
    overflow-y: auto;
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
  }
  .close-btn {
    width: 1.5rem;
    cursor: pointer;
    .fa {
      font-size: 14px;
      color: #22222296;
      &:hover {
        color: var(--button-secondary);
      }
    }
    //   img {
    //     width: 100%;
    //   }
  }
  .color {
    width: 80%;
    &-input {
      background: 0 0;
      opacity: 0;
      border: none;
      border-radius: 3px;
      float: left;
      position: relative;
      z-index: 9999;
      width: 33px;
      height: 35px;
    }
    &-level {
      padding: 2px;
      border-radius: 4px;
      border: 1px solid #d5d6d6;
      background-color: #edf1f2;
      border-left: 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      &.color-planarea {
        width: 87%;
      }
      .color-bg {
        // background-image: url(../src/assets/images/jpg-icon/color_image.jpg);
        width: 50px;
        height: 37px;
        position: absolute;
        top: 0;
        left: 0;
        background-size: 100% !important;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        background-position: center center !important;
        &.color-pic {
          width: 3em;
          // background-color: #cc0000;
          // border: 1px solid #cc0000;
          background-image: none;
          top: 18px;
        }
      }
      .color-text,
      .edit-color-text {
        display: block;
        padding: 5px 5px 6px 25px;
      }
      .edit-color-text {
        padding: 5px 5px 6px 25px;
      }
    }
  }
}

// ------ Style of the Table
.table {
  thead tr th,
  thead td {
    font-size: $globle-text;
    font-weight: $font-bold;
    color: var(--primary-text-color);
    border-bottom-width: 1px;
    border-bottom: 1px solid #dee2e6;
    padding: 0.75rem 0.45rem;
  }
  th,
  td {
    vertical-align: middle;
  }
  tbody td {
    padding: 0.45rem 0.45rem;
    font-size: $globle-text;
    color: var(--secondary-text-color);
    line-height: 1.52857143;
  }
  .table-button {
    justify-content: center;
    &-link {
      height: 31px;
      padding: 8px 10px;
      &-active {
        padding: 8px 16px;
      }
    }
  }
}
.table-hover > tbody > tr:hover,
.table-striped > tbody > tr:nth-of-type(odd),
.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > thead > tr.active > th,
.table > thead > tr > td.active,
.table > thead > tr > th.active {
  background-color: #fafbfc;
}
.table-hover > tbody > tr:hover:nth-of-type(odd) {
  background-color: #fafbfc;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #eef2f5;
}

// ------ Style of the Table profile avatar color
.avatar-content {
  justify-content: flex-start;
  align-items: center;
  color: var(--secondary-text-color);
  &:hover,
  &:focus {
    color: var(--secondary-text-color);
    text-decoration: none;
  }
  .avatar {
    position: relative;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: 3px solid #ffffff;
    overflow: hidden;
    margin-right: 0.7em;
    box-shadow: 0px 0px 6px #d4d4d4;
    &-image {
      max-width: 100%;
      height: 100%;
    }
  }
}

// ------ Style of the Table show entries drop down
.showentries {
  min-width: 32%;
  &-select {
    background: #fbfbfb;
    border: 1px solid #ddd;
    border-radius: $border-radius;
    &:focus {
      border: 1px solid var(--theme-background);
      outline: none;
    }
  }
  &-label {
    font-weight: $font-bold;
    padding-top: 0.7em;
    font-size: $globle-text;
  }
}

// ------ Style of the pagination
.data {
  &-entries {
    font-size: 0.66rem;
    color: var(--secondary-text-color);
  }
}
.pagination-content {
  justify-content: space-between;
  align-items: center;
  padding: 0em;
}

// new pagination style starts
.ngx-pagination {
  margin-bottom: 0px !important;
  display: flex;
  align-items: center;
  li {
    margin: 0px 1px;
    display: flex;
    border-radius: 0px !important;
    font-size: 12px;
    position: relative;
  }
  li {
    &.current {
      span {
        // padding:7px 5px !important;
        font-size: 12px;
      }
    }
    a {
      padding: 7px 7px !important;
      border-radius: 0px !important;
      font-size: 12px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      &:hover {
        border: 1px solid var(--panel-primary);
        background: var(--panel-primary);
      }
    }
  }
  li.pagination-previous {
    &.disabled {
      padding: 7px 12px !important;
    }
    a {
      padding: 7px 12px !important;
    }
  }
  li.pagination-next {
    &.disabled {
      padding: 7px 12px !important;
    }
    a {
      padding: 7px 12px !important;
    }
  }
}
.ngx-pagination .current {
  border-radius: 2px;
  padding: 7px 7px !important;
  font-size: 12px;
  min-width: 1.5em;
  color: #fff;
  border: 1px solid var(--panel-primary);
  background: var(--panel-primary) !important;
}
.ngx-pagination a,
.ngx-pagination button {
  border: 1px solid #575757;
  background: #575757;
  padding: 7px 7px;
  outline: none !important;
  color: #fff !important;
  border-radius: 0px;

  &:focus {
    // border: none !important;
    // outline: none !important;
  }
  &:hover {
    border: 1px solid var(--panel-primary);
    background: var(--panel-primary) !important;
  }
}
.ngx-pagination .pagination-previous a,
.ngx-pagination .pagination-previous.disabled {
  &::before {
    margin-right: 0.1rem !important;
    position: absolute;
    left: 4px;
    top: 8px;
  }
}
.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
  margin-left: 0.1rem !important;
  position: absolute;
  right: 3px;
  top: 8px;
}
.ngx-pagination .small-screen {
  display: none !important;
}
.showentries-pagination-cell {
  color: #697077;
  font-size: 10px;
  padding-top: 7px;
}
.ngx-pagination .disabled {
  padding: 7px 7px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

// ------ Style of the table seach field
.search-input {
  height: 2.74em;
  padding: 8px 10px;
  background-color: #fbfbfb;
  background-image: none;
  border: 1px solid #ddd;
  border-radius: $border-radius;
  &:focus {
    border: 1px solid var(--theme-background);
    outline: none;
    box-shadow: none;
  }
  &.search-width {
    max-width: 100%;
    min-width: 100%;
  }
}

.alert {
  &-purple {
    background-color: #7266ba;
    border-color: #7266ba;
    color: #fff;
    font-size: $globle-large;
    font-weight: $font-bold;
  }
  &-yellow {
    color: #fad732;
    &:hover {
      color: #fad732;
    }
  }
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked {
  font-weight: normal;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 200px;
  text-align: left;
  word-wrap: break-word;
  white-space: normal;
  font-size: 9pt;
  border-bottom: 1px solid #f5f7fa;
}

.select-facility {
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
  .ng-dropdown-panel
    .ng-dropdown-panel-items
    .ng-option.ng-option-selected.ng-option-marked {
    display: flex;
    align-items: center;
    color: #262626;
    background-color: #fff;
    .checkbox-label-text{
      font-weight: 400;
      cursor: pointer;
    }
    &.ng-option {
      display: flex;
      align-items: center;
      padding:6px 10px;
      cursor: pointer;
    }
    &:hover{
      background-color: #f5f5f5;
    }
  }
  .ng-option.ng-option-selected{
    background-color: #fff;
    &:hover{
      background-color: #f5f5f5;
    }
    .checkbox-label-text{
      font-weight: 400;
      cursor: pointer;
    }
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    display: flex;
    align-items: center;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    display: flex;
    align-items: center;
    padding:6px 10px;
    cursor: pointer;
    .checkbox-label-text{
      font-weight: 400;
      cursor: pointer;
      line-height: 20px;
      &:after{
        top:9px;
      }
    }
  }
}

// ------ Style the Breadcrumb
.breadcrumb-content {
  width:100%;
  border-bottom: 1px solid #c1c6ca;
  padding: 6px 0;
  margin-bottom: 1.26em;
  justify-content: space-between;
  align-items: center;
  .breadcrumb {
    padding: 8px 0;
    margin-bottom: 0;
    list-style: none;
    background-color: #f5f7fa;
    border-radius: 0;
    &-item {
      font-size: $globle-text;
      .breadcrumb-link {
        color: var(--dark-text-color);
      }
      &.active {
        font-weight: $font-bold;
        color: var(--primary-text-color);
      }
    }
  }
}

.table-list-data {
  max-width: 100%;
  border-radius: 4px;
}

.table-list-box {
  // display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
}

.cdk-drag-preview {
  // box-sizing: border-box;
  border-radius: 0px;
  padding: 8px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-box:last-child {
  border: none;
}

.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.input-element.ng-select .ng-select-container {
  border: none !important;
  &:hover {
    box-shadow: none !important;
  }
}

.toast-error,
.ngx-toastr {
  .toast-title {
    font-size: $globle-text-second;
  }
  .toast-message {
    font-size: $globle-text;
    padding-top: 7px;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
// .ng-select .ng-select-container{
//   border:none !important;
//   &:hover{
//     box-shadow: none !important;
//   }
// }
.activeTab {
  background-color: #125272;
  color: #fff;
}
.control-mainrow .cb {
  border-right: 1px solid #ccc;
  color: #656565;
}
.activeTab,
.control-mainrow .cb {
  padding: 7px 20px;
  border-top: 1px solid #ccc;
  font-weight: 700;
  float: left;
}

.form-input {
  background-color: #fbfbfb;
}
.arrow-link {
  color: #5d9cec;
  &:hover {
    color: #1b72e2;
  }
}
.breadcrumb > li {
  font-size: 13px;
}
// Calendar
.datepicker-theme {
  @mixin reset-btn {
    border: 1px solid #dcdcdc;
    font-size: 12px;
    margin: 0;
    // padding: 10px;
    height: 30px;
    background-color: #fff;
    border-radius: 5px;

    &:hover {
      background-color: #dcdcdc;
    }
  }

  &.dp-popup {
    .dp-day-calendar-container {
      border-radius: 5px;
      box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.5);
      background-color: #fff;
    }
    .dp-calendar-nav-container {
      height: 38px;
      border: none;

      .dp-nav-header-btn {
        @include reset-btn;
        font-weight: 600;
      }
    }

    .dp-nav-btns-container {
      .dp-calendar-nav-left {
        @include reset-btn;
        width: auto;
      }
      .dp-current-location-btn {
        margin: 0 5px;
        // @include reset-btn;
        // width: auto;
        // &::after {
        //   content: "Current month";
        // }
      }
      .dp-calendar-nav-right {
        @include reset-btn;
        width: auto;
      }
    }

    .dp-calendar-wrapper {
      border: none;
      padding: 5px;

      .dp-weekdays {
        .dp-calendar-weekday {
          // @include reset-btn;
          border: none;
          font-size: 12px;
          padding-top: 5px;
        }
      }
      .dp-calendar-week {
        .dp-week-number {
          position: relative;
          display: inline-block;
          width: 30px;
          text-align: center;
          font-size: 12px;
        }

        .dp-calendar-day {
          &.dp-prev-month {
            @include reset-btn;
          }
          &.dp-current-month {
            @include reset-btn;
          }
          &.dp-next-month {
            @include reset-btn;
          }

          &.dp-current-day {
            color: #31b0d5;
          }
          &.dp-selected {
            background-color: #31b0d5;
            color: #fff;
          }
        }
      }
      .dp-months-row {
        .dp-calendar-month {
          @include reset-btn;

          &.dp-selected {
            background-color: #31b0d5;
            color: #fff;
          }
        }
      }
    }
  }
}

// MAP LOCATION
.map-dtg {
  &.map-popup-bg {
    padding: 0px 0px;
    h3 {
      &.namefild {
        background: #334251;
        margin-bottom: 5px;
        font-size: 13px;
        padding: 2px 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        span {
          &.namecell {
            color: #fff;
          }
        }
      }
    }
    .rowcell-mapgeo {
      padding: 0px 10px 5px;
      width: 100%;
      float: left;
      span {
        &.geo-lefthdl {
          font-size: 11px;
          color: #304050 !important;
          font-weight: normal;
          float: left;
          line-height: 16px;
          padding-right: 5px;
        }
      }
      h4 {
        &.geoname {
          float: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-top: 0px;
          margin-bottom: 0px;
          text-align: left;
          line-height: 17px;
          color: #304050 !important;
          font-size: 11px;
          font-weight: 600;
          width: 100%;
          span {
            &.ri-rai {
              width: 95%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #304050 !important;
            }
          }
        }
      }
    }
  }
}

.field-disabled {
  background-color: #edf1f2 !important;
  cursor: not-allowed;
}

.dateppicker-input {
  .datepicker-theme {
    div {
      top: 0px !important;
      left: 0px !important;
      dp-calendar-nav .dp-nav-header {
        left: 5px !important;
        top: 50% !important;
      }
      dp-calendar-nav .dp-nav-btns-container {
        right: 5px !important;
        top: 50% !important;
        left: auto !important;
      }
    }
  }
}
.ng-dropdown-panel .scroll-host {
  box-shadow: 0px 5px 10px rgb(138 138 145 / 36%);
}
.select-button {
  &.multi-select-dropdown {
    &.week-select {
      .multiselect-dropdownn {
        .selected-item {
          border: none;
          margin-right: 4px;
          background: none;
          padding: 0 5px;
          color: #fff;
        }
      }
    }
  }
}

// Css for map floating menu options
.filter-homerow {
  position: fixed;
  z-index: 10000;
  border-radius: 3px;
  left: 85px;
  top: 70px;
  ul {
    &.filter-cell {
      background-color: #fff !important;
      float: left;
      border-radius: 3px;
      margin: 0px;
      padding: 0px;
      box-shadow: 0px 0px 10px rgba(97, 97, 97, 0.281);
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        list-style: none;
        position: relative;
        border-right: 1px solid rgba(204, 204, 204, 0.38);
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        &:last-child {
          list-style: none;
          border-right: none;
        }
      }
    }
  }
}

.filter-homerow {
  ul {
    &.filter-cell {
      li {
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          &.map-homelink {
            float: left;
            font-size: 11px;
            padding: 8px 10px;
            border: none;
            color: #334251;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            &:hover {
              color: #334251;
            }
            span {
              background: url(./assets/images/Beaconmap-filtericon.svg);
              background-position: 0px -23px;
              background-repeat: no-repeat;
              float: left;
              width: 17px;
              height: 16px;
            }
          }
          &.map-filterlink {
            float: left;
            font-size: 11px;
            padding: 8px 10px;
            border: none;
            color: #334251;
            span {
              &.flt-icon {
                background: url(./assets/images/Beaconmap-filtericon.svg);
                background-position: 0px 3px;
                background-repeat: no-repeat;
                float: left;
                width: 17px;
                height: 16px;
              }
              &.favou-icon {
                background: url(./assets/images/Beaconmap-filtericon.svg);
                background-position: -28px 2px;
                background-repeat: no-repeat;
                float: left;
                width: 17px;
                height: 16px;
                &:hover {
                  background: url(./assets/images/Beaconmap-filtericon.svg);
                  background-position: -28px -23px;
                  background-repeat: no-repeat;
                  float: left;
                  width: 17px;
                  height: 16px;
                }
                &.active {
                  background: url(./assets/images/Beaconmap-filtericon.svg);
                  background-position: -28px -23px;
                  background-repeat: no-repeat;
                  float: left;
                  width: 17px;
                  height: 16px;
                }
              }
              &.radio-bullet {
                position: absolute;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #05a34e;
                left: 17px;
                top: 10px;
              }
            }
            &:hover,
            &:focus {
              color: #334251;
              background-color: #f5f7fa;
              border-top-right-radius: 3px;
              border-bottom-right-radius: 3px;
            }
          }
          &:hover,
          &:focus {
            color: #334251;
            background-color: #f5f7fa;
          }
        }
      }
    }
  }
  &:hover,
  &:focus {
    color: #334251;
    background-color: #f5f7fa;
    border-radius: 3px;
  }
}

// Css for search input

input.search-inputicon {
  outline: none;
  padding-right: 10px;

  &[type="search"] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    font-family: inherit;
    font-size: 100%;
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button {
    display: none;
  }

  &[type="search"] {
    background: url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png)
      no-repeat right center;
    border-bottom: solid 1px #ccc;
    padding: 9px 32px 9px 9px;
    width: 55px;
    border-radius: 0;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;

    &:focus {
      width: 220px;
      background-color: #fff;
      border-bottom: 1px solid #334251;
      box-shadow: none;
      border-top: none;
      border-left: none;
      border-right: none;
    }
  }

  &:-moz-placeholder,
  &::-webkit-input-placeholder {
    color: #999;
  }
}

/* searchfild-animated css */
.searchfild-ani {
  float: right;
  width: 100%;
  margin-top: 20px;
  input.search-inputicon {
    &[type="search"] {
      width: 15px;
      padding-right: 18px;
      color: transparent;
      cursor: pointer;
      float: right;

      &:hover {
        background-color: #fff;
      }

      &:focus {
        width: 220px;
        padding-right: 32px;
        color: #000;
        background-color: #fff;
        cursor: auto;
      }
    }

    &:-moz-placeholder,
    &::-webkit-input-placeholder {
      color: transparent;
    }
  }
}

// TABLE PAGITIONS NOUMBER CSS
.select-field-mat.table-pagi {
  width: 80px;
  float: right;
  .mat-form-field-underline {
    height: 0px;
    display: none;
  }
  .mat-form-field-wrapper {
    padding-bottom: 0em;
    .mat-form-field-infix {
      padding: 0rem !important;
      border-top: 0em solid transparent;
    }
  }
  .mat-select {
    border: 1px solid;
    border-color: #4950573d;
    border-radius: 3px;
    font-size: 10px;
    color: #334251;
    cursor: pointer;
    padding: 6px 8px 6px 8px;
    font-family: "robotomedium";
    height: 32px;
    line-height: 18px;
  }
  .mat-select-arrow {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid;
    margin: 0 4px;
  }
}

// MAT DROPDOWN SELECT FIELD CSS
.mat-select-field.mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0px;
}
.mat-select-field.mat-form-field-appearance-legacy {
  .mat-form-field-wrapper {
    padding-bottom: 0em;
  }
  .mat-form-field-infix {
    padding: 3px 0px 4px;
    border: none;
  }

  .mat-select-arrow {
    margin: 0px;
  }

  .mat-form-field-ripple,
  .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: transparent;
  }
}

.main-maplist.toggleco {
  top: 180px !important;
}
.main-maplist.googleundo.toggleco {
  top: 145px !important;
}
.main-maplist.toggleco button.btn-undo.togglecolor {
  padding: 9px 12px;
}
.main-maplist.toggleco button.btn-undo.togglecolor img {
  height: 17px;
  width: 17px;
}

.main-maplist button.btn-undo {
  margin-top: 12px;
  opacity: 1;
  margin-left: 25px;
  border-radius: 6px;
  color: #fff;
  padding: 9px 15px;
  background: #f5faff !important;

  &:focus,
  &:active:focus,
  &.active:focus {
    outline-offset: inherit !important;
    box-shadow: none !important;
  }

  i.fa-undo {
    opacity: 1;
    box-shadow: none;
    color: #323d48;
  }

  &.disabled {
    opacity: 0.65 !important;
  }
}

#map {
  max-height: 100%;
  height: 100vh;
  background: rgba(24, 37, 24, 0);
  z-index: 99;
  cursor: default;
  &.mapcl {
    position: fixed;
    width: 100%;
  }
  &.mapTF {
    position: fixed;
    width: 100%;
  }
}
.mapbeaconlist {
  margin-right: 12px;
}
.mapcontrolTF {
  position: relative;
  .mapTF {
    .leaflet-control-container {
      position: fixed;
      right: 0px;
    }
  }
}

.searchworker {
  float: left;
  position: relative;
  width: 100%;
  margin: 0px 0px 8px 0px;
  border-bottom: 1px solid #d3d6da;
}
.searchworker .form-group {
  margin-bottom: 0px;
}
.searchworker input.SearchBox {
  background: #fff;
  border: none;
  width: 100%;
  padding: 0px 28px 5px 0px;
  position: relative;
}
.searchworker span.searchicon {
  background: url(./assets/images/whiteboard-icon.svg);
  background-position: -50px -38px;
  top: 8px;
  background-repeat: no-repeat;
  width: 23px;
  height: 27px;
  right: 0px;
  position: absolute;
}
.searchworker input.SearchBox::placeholder {
  color: rgb(208, 213, 218);
}
.searchworker input.SearchBox.subpgsearch {
  padding: 10px 28px 10px 8px;
}

.searchworker span.searchicon:-moz-placeholder,
.searchworker span.searchicon::-moz-placeholder,
.searchworker span.searchicon:-ms-input-placeholder {
  color: #d8dcdf;
}
.searchlayer.show
  > .dropdown-menu.search-ar1.whiteboardart.show
  .searchworker
  span.searchicon {
  top: 0px;
}

.containerprogr strong {
  text-align: left;
  float: left;
  color: #334251;
  font-size: 15px;
  margin-bottom: 1px;
}
.containerprogr button.prog-back {
  float: right;
  border: 1px solid #e6e6e6;
  background: #fff;
  padding: 6px 17px;
  border-radius: 3px;
  margin-right: 8px;
  font-weight: 400;
  font-family: "robotoregular";
}
.containerprogr .h3-hedding {
  font-weight: normal;
  font-family: "robotomedium";
  letter-spacing: 0.8px;
  font-size: 13px;
  float: left;
  width: 100%;
  margin: 0px 0px 15px;
  color: #5c6874;
}
.containerprogr .progress {
  background: #eceef0 !important;
  margin-bottom: 0px;
  height: 26px;
  float: left;
  width: 99%;
  margin-top: 0px;
  overflow: inherit;
  border-radius: 0px;
}
.containerprogr .progress .progress-bar {
  background-color: #334251;
  position: relative;
}
.containerprogr .progress .progress-bar span {
  position: absolute;
  top: -33px;
  right: -26px;
  background: #fff;
  box-shadow: 0px 0px 17px #d6cfcf;
  border-radius: 3px;
  padding: 1px 3px;
  color: #334251;
  font-weight: 400;
  font-size: 10px;
  min-width: 60px;
}
.containerprogr .h3-hedding span {
  color: #06a348;
}
.containerprogr .progress .progress-bar span::before {
  position: absolute;
  content: " ";
  top: 23px;
  right: 25px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #fff;
}
.containerprogr span.barpro {
  float: left;
  background: #fff;
  box-shadow: 0px 0px 0px #d6cfcf;
  border-radius: 3px;
  padding: 5px 0px;
  color: #334251;
  font-weight: 400;
  font-size: 11px;
}
.containerprogr .col-cell {
  border-right: 1px solid #f1f1f1;
  width: 100%;
  margin: 10px 0px 3px;
}
.containerprogr .col-cell p {
  font-size: 13px;
  color: #334251;
  width: 100%;
  margin: 0px;
  padding: 8px 0px 4px;
  font-weight: 400;
}
.containerprogr .col-cell span {
  font-size: 13px;
  color: #06a348;
  width: 100%;
  font-weight: 400;
}
.searchworker span.searchicon {
  &:-moz-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    color: #d8dcdf;
  }
}
.searchworker span.searchicon {
  background-position: -50px -44px;
}
ngx-daterangepicker-material {
  position: absolute;
  top: 50px;
  width: 775px;
  right: 20px;
  .md-drppicker {
    &.shown.drops-down-right {
      width: 100%;
    }

    &.ltr .ranges {
      float: right;
      position: relative;
      min-width: 185px;
      width: 185px;
      padding-left: 16px;

      &:before {
        content: "";
        position: absolute;
        width: 2px;
        background-color: rgba(234, 236, 238, 0.6) !important;
        height: 295px;
        top: 8px;
        left: 0px;
      }

      ul {
        list-style: none;
        padding-top: 8px;
        float: left;
        width: 100%;

        li {
          float: left;
          width: 100%;
          list-style: none;
          padding: 2px 0px;

          &:hover {
            background-color: #def4e7 !important;
          }

          &:last-child {
            display: none;
          }

          button {
            padding: 5px 0px;
            letter-spacing: 1px;
            font-size: 13px;
            font-family: "robotomedium";
            color: #334251;
            font-weight: 500;
            width: 100%;

            &.active {
              background-color: transparent;
              color: #19ae59 !important;
            }
          }
        }
      }
    }

    &.shown.drops-down-right .calendar.left {
      position: relative;

      .calendar-table {
        position: relative;
        padding-right: 35px;
      }
    }
  }

  .calendar.left .calendar-table table.table-condensed tbody:after {
    content: "";
    position: absolute;
    width: 2px;
    background-color: rgba(234, 236, 238, 0.6) !important;
    height: 180px;
    top: 0px;
    right: -20px;
    margin: 0px;
  }

  .md-drppicker {
    .calendar {
      td,
      th {
        width: 34px;
        height: 34px;
        font-size: 13px;
        opacity: 1;
        font-family: "robotomedium";
        color: #334251;
        font-weight: 500;
      }
    }

    &.shown.drops-down-right .calendar {
      max-width: 300px;
    }

    .calendar th {
      &.prev,
      &.month {
        font-size: 14px;
        letter-spacing: 1px;
        padding-bottom: 20px;
        font-family: "robotoRegular";
        color: #334251;
        font-weight: 600;
      }

      span {
        font-size: 13px;
        letter-spacing: 1px;
        padding-bottom: 20px;
        font-family: "robotoRegular";
        color: #334251;
        font-weight: 600;
      }
    }

    td {
      &.active {
        background-color: #19ae59 !important;
        border-color: transparent;
        color: #fff !important;
        border-radius: 50% !important;

        &:hover {
          background-color: #19ae59 !important;
          border-color: transparent;
          color: #fff !important;
          border-radius: 50% !important;
        }
      }

      &.end-date {
        border-radius: 50% !important;
      }
    }

    &.shown.drops-down-right .calendar table {
      border-collapse: inherit;
    }

    td {
      &.in-range {
        background-color: #def4e7 !important;
        color: #334251;
      }

      &.active {
        &.start-date.in-range,
        &.end-date {
          background-color: #19ae59 !important;
        }
      }

      &.available:hover {
        background-color: #def4e7 !important;
        opacity: 1 !important;
        color: #334251 !important;
        border-radius: 0px !important;
      }
    }

    th.available:hover {
      background-color: #def4e7 !important;
      opacity: 1 !important;
      color: #334251 !important;
      border-radius: 0px !important;
    }

    &.ltr .buttons .buttons_input button.btn {
      &.clear {
        position: relative;
        margin-left: 15px;
        margin-right: 10px;
        border: 1px solid #ccc;
        float: right;
        color: #334251 !important;
        background-color: #ffffff !important;
        padding: 6px 12px;
        width: 73px;
        height: 36px;
        bottom: 0px;
        margin-top: 20px;

        &:before {
          position: absolute;
          content: " ";
          color: #334251;
          padding: 6px 2px;
          width: 73px;
          background: rgba(255, 0, 0, 0);
          border-radius: 0px;
        }

        svg {
          display: none;
        }
      }

      background-color: #334251;
      border-radius: 4px;
      box-shadow: none;
      color: #fff;
      text-transform: capitalize;
      line-height: 18px;
      padding: 9px 15px;
      margin-top: 20px;
    }
  }
}

table.table-beacone {
  thead tr {
    border-top: 2px solid #eff1f2;
    border-bottom: 2px solid #eff1f2;
    background: #f6f7f9;

    th {
      font-weight: 600;
      color: #334251;
      font-size: 12.5px !important;
      padding: 0.55rem !important;
      vertical-align: middle;
      font-family: "robotomedium";
    }
  }

  tbody tr {
    td {
      font-weight: 400;
      color: #334251;
      vertical-align: middle;
      font-size: 12px !important;
      font-family: "robotoregular";
      word-break: break-word;

      &:first-child {
        font-weight: 600;
        font-size: 12px;
        color: #334251;
        font-family: "robotomedium";
      }

      &.td-center-align {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      i.fa-ellipsis-v {
        font-size: 17px;
        color: #334251;
        text-decoration: none;
      }

      a {
        &.btn-default {
          padding: 3px;
        }

        &.dragtable {
          padding: 3px 8px;
        }
      }

      input.In-contbeacan,
      select.In-contbeacan {
        height: 34px;
      }
    }

    &:last-child {
      border-bottom: 1px solid #ececec;
    }

    border-bottom: none;
  }

  thead tr th:first-child {
    border-bottom: none;
    vertical-align: middle;
  }

  tbody tr {
    &:first-child td {
      border-bottom: none;
      border-top: none;
      vertical-align: middle;
    }

    &.taskPros-tr td {
      padding: 0px !important;

      .containerprogr {
        border-top: 10px solid #edeef0;
        border-bottom: 10px solid #edeef0;
        position: relative;
        margin: 10px 0px 5px;
        padding: 13px 0px;

        .pro-wth {
          width: 12%;
        }
      }
    }
  }

  &.ta-tpnav tbody tr {
    &:nth-child(1)
      span.dottednav
      ul.subellip-nav.tf-tbl
      li.dropdown
      ul.dropdown-menu.tablemenu,
    &:nth-child(2)
      span.dottednav
      ul.subellip-nav.tf-tbl
      li.dropdown
      ul.dropdown-menu.tablemenu,
    &:nth-child(3)
      span.dottednav
      ul.subellip-nav.tf-tbl
      li.dropdown
      ul.dropdown-menu.tablemenu,
    &:nth-child(4)
      span.dottednav
      ul.subellip-nav.tf-tbl
      li.dropdown
      ul.dropdown-menu.tablemenu,
    &:nth-child(5)
      span.dottednav
      ul.subellip-nav.tf-tbl
      li.dropdown
      ul.dropdown-menu.tablemenu,
    &:nth-child(6)
      span.dottednav
      ul.subellip-nav.tf-tbl
      li.dropdown
      ul.dropdown-menu.tablemenu,
    &:nth-child(7)
      span.dottednav
      ul.subellip-nav.tf-tbl
      li.dropdown
      ul.dropdown-menu.tablemenu,
    &:nth-child(8)
      span.dottednav
      ul.subellip-nav.tf-tbl
      li.dropdown
      ul.dropdown-menu.tablemenu {
      top: 10px;
      left: -148px;
    }
  }
}

span.datepicker-icon {
  position: absolute;
  right: 1px;
  top: 1px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: #edeef0 url(./assets/images/small-calendar.svg) no-repeat;
  background-position: 9px 9px;
  width: 36px;
  height: 34px;
}

// Schedule Assinment Css Date Picker
.assistance-datebg {
  ngx-daterangepicker-material {
    position: absolute;
    top: 50px;
    width: 585px;
    right: 20px;
    .md-drppicker {
      &.shown.drops-down-right {
        width: 100%;
      }

      &.ltr .ranges {
        float: right;
        position: relative;
        min-width: 185px;
        width: 185px;
        padding-left: 16px;

        &:before {
          content: "";
          position: absolute;
          width: 0px;
          background-color: transparent !important;
          height: 295px;
          top: 8px;
          left: 0px;
        }

        ul {
          list-style: none;
          padding-top: 8px;
          float: left;
          width: 100%;

          li {
            float: left;
            width: 100%;
            list-style: none;
            padding: 2px 0px;

            &:hover {
              background-color: #def4e7 !important;
            }

            &:last-child {
              display: none;
            }

            button {
              padding: 5px 0px;
              letter-spacing: 1px;
              font-size: 13px;
              font-family: "robotomedium";
              color: #334251;
              font-weight: 500;
              width: 100%;

              &.active {
                background-color: transparent;
                color: #19ae59 !important;
              }
            }
          }
        }
      }

      &.shown.drops-down-right .calendar.left {
        position: relative;

        .calendar-table {
          position: relative;
          padding-right: 35px;
        }
      }
    }

    .calendar.left .calendar-table table.table-condensed tbody:after {
      content: "";
      position: absolute;
      width: 2px;
      background-color: rgba(234, 236, 238, 0.6) !important;
      height: 180px;
      top: 0px;
      right: -20px;
      margin: 0px;
    }

    .md-drppicker {
      .calendar {
        td,
        th {
          width: 34px;
          height: 34px;
          font-size: 13px;
          opacity: 1;
          font-family: "robotomedium";
          color: #334251;
          font-weight: 500;
        }
      }

      &.shown.drops-down-right .calendar {
        max-width: 300px;
      }

      .calendar th {
        &.prev,
        &.month {
          font-size: 14px;
          letter-spacing: 1px;
          padding-bottom: 20px;
          font-family: "robotoRegular";
          color: #334251;
          font-weight: 600;
        }

        span {
          font-size: 13px;
          letter-spacing: 1px;
          padding-bottom: 20px;
          font-family: "robotoRegular";
          color: #334251;
          font-weight: 600;
        }
      }

      td {
        &.active {
          background-color: #19ae59 !important;
          border-color: transparent;
          color: #fff !important;
          border-radius: 50% !important;

          &:hover {
            background-color: #19ae59 !important;
            border-color: transparent;
            color: #fff !important;
            border-radius: 50% !important;
          }
        }

        &.end-date {
          border-radius: 50% !important;
        }
      }

      &.shown.drops-down-right .calendar table {
        border-collapse: inherit;
      }

      td {
        &.in-range {
          background-color: #def4e7 !important;
          color: #334251;
        }

        &.active {
          &.start-date.in-range,
          &.end-date {
            background-color: #19ae59 !important;
          }
        }

        &.available:hover {
          background-color: #def4e7 !important;
          opacity: 1 !important;
          color: #334251 !important;
          border-radius: 0px !important;
        }
      }

      th.available:hover {
        background-color: #def4e7 !important;
        opacity: 1 !important;
        color: #334251 !important;
        border-radius: 0px !important;
      }

      &.ltr .buttons .buttons_input button.btn {
        &.clear {
          position: relative;
          margin-left: 15px;
          margin-right: 10px;
          border: 1px solid #ccc;
          float: right;
          color: #334251 !important;
          background-color: #ffffff !important;
          padding: 6px 12px;
          width: 73px;
          height: 36px;
          bottom: 0px;
          margin-top: 5px;

          &:before {
            position: absolute;
            content: "Cancel";
            color: #334251;
            padding: 6px 2px;
            width: 73px;
            background: rgba(255, 0, 0, 0);
            border-radius: 0px;
          }

          svg {
            display: none;
          }
        }

        background-color: #334251;
        border-radius: 4px;
        box-shadow: none;
        color: #fff;
        text-transform: capitalize;
        line-height: 18px;
        padding: 9px 15px;
        margin-top: 5px;
      }
    }
  }
}

/* calendar */
.formmodal_box {
  float: left;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;

  .box {
    background: #fff;
    margin-top: 10px;
    float: left;
    width: 100%;
  }

  .form-group {
    float: left;
    width: 100%;
  }

  .box .form-group label.control-label {
    padding: 8px 0px;
    color: #304050;
    font-family: "robotoregular";
  }

  .form-group input.form-control {
    background-color: transparent;
    border: 1px solid #d4d9e2;
    height: 43px;
    font-size: 13px;
    padding-right: 30px;

    &:focus {
      border: 1px solid #2c3a49 !important;
      box-shadow: none;
    }

    &::placeholder {
      font-size: 13px !important;
    }
    &.search-layers {
      &:focus {
        border: 0px !important;
      }
    }
  }
}

.formmodal_box .form-group label.control-label {
  color: #4e5b67;
  font-weight: bold !important;
}
.formmodal_box {
  &.dummytext {
    background: #e7f2ec;
    width: 100%;
    padding: 10px 16px;
    margin: 8px 0px 13px 0px;

    ul {
      list-style: none;
      width: auto;
      float: left;
      padding: 0px 16px;
      margin: 0px;

      li {
        list-style: disc;
        width: 100%;
        font-size: 13px;
        font-weight: 400;
        color: #304050;
        font-family: "robotoregular";
        float: left;
        padding: 5px 0px 5px 0px;
        margin: 0px;

        span {
          color: #06a348;
        }

        &:last-child {
          list-style: disc;
        }
      }
    }
  }

  &.search-tsp {
    width: 60%;
    margin-top: 25px;
    float: right !important;

    .searchworker span.searchicon {
      background-position: -50px -44px;
    }
    .searchworker span.searchclearicon {
      font-size: 1.3em;
      margin-top: 8px;
      margin-left: -40px;
      cursor: pointer;
      position: absolute;
      z-index: 999;
      background-position: -50px -44px;
    }
  }
}
.searchworker span.searchclearicon {
  &:-moz-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    color: #d8dcdf;
  }
}
/* Google Map */
.si-close-button {
  display: none;
}

.leaflet-container
  .gol-map
  .sebm-google-map-container-inner
  .gm-style
  .gm-bundled-control-on-bottom {
  top: 198px !important;
  height: 190px;
  width: 43px;
  margin: 0px !important;
  right: 8px !important;
  position: fixed !important;

  .gmnoprint {
    position: relative !important;
    top: 0px !important;
    right: 0px;
    height: 91px;
    display: inherit !important;
    left: 0px !important;
  }
}

.gol-map .gmnoprint.gm-bundled-control .gmnoprint button.gm-control-active img {
  height: 13px !important;
  width: 13px !important;
  margin: 0px !important;
}

.leaflet-container {
  &.maplocation-map
    .gol-map
    .sebm-google-map-container-inner
    .gm-style
    .gm-bundled-control-on-bottom {
    top: 166px !important;
    height: 190px;
    width: 43px;
    margin: 0px !important;
    right: 8px !important;
    position: fixed !important;
  }

  &.newcellzoomin .gol-map.zoominTop {
    margin-top: 0px;
    position: relative;
    right: 30px;

    .sebm-google-map-container-inner .gm-style .gm-bundled-control-on-bottom {
      top: 166px !important;
    }
  }
}

.gol-map {
  .si-content-wrapper {
    padding: 0px;
    width: 275px;
    border: none !important;
    box-shadow: 0px 0px 15px rgba(55, 59, 64, 0.38);
    border: none;
    border-radius: 6px;
    .si-content {
      width: 100%;
      max-width: 100% !important;
    }
    .map-dtg {
      .ar-t1 {
        float: left;
        width: 60%;
        padding-right: 10px;
        color: #334251;
        font-size: 12px;
        font-family: "robotomedium";
        span {
          font-family: "robotoregular";
          padding-left: 3px;
        }
      }
      .ar-t2 {
        float: left;
        width: 40%;
        color: #334251;
        font-size: 12px;
        font-family: "robotomedium";
        span {
          font-family: "robotoregular";
          padding-left: 3px;
        }
      }
    }
  }
  .gmnoprint {
    &.gm-bundled-control {
      .gmnoprint {
        button {
          &.gm-control-active {
            background: #f5faff !important;
            top: 3px !important;
            position: relative !important;
            border-radius: 6px !important;
            margin-bottom: 3px !important;
          }
        }
        div {
          background-color: transparent !important;
        }
      }
      .gm-svpc {
        display: none !important;
      }
    }
  }
  &.zoominTop {
    .sebm-google-map-container-inner {
      .gm-style {
        .gm-bundled-control-on-bottom {
          top: 50px !important;
          margin: 0px 9px 0px 0px !important;
          height: 88px !important;
          right: 0px !important;
        }
      }
    }
    .gmnoprint {
      &.gm-bundled-control {
        .gmnoprint {
          position: initial !important;
          div {
            width: 43px !important;
          }
        }
      }
    }
  }
}

body.modal-open {
  .modal-backdrop.fade.show.staff-sm-whow {
    background: #2e3d4cba !important;
    z-index: 99999 !important;
  }
}
.modal-open .modal {
  overflow-y: hidden !important;
  position: fixed !important;
  z-index: 99999;
  &.fade {
    opacity: 1 !important;
  }
}

.assigned-facility-list {
  min-height: 50px;
}

.fa-arrows-alt {
  color: #232323;
  font-size: 10px;
}

.ngx-pagination-arrow {
  .ngx-pagination .pagination-previous a::before,
  .ngx-pagination .pagination-previous.disabled::before,
  .ngx-pagination .pagination-next a::after,
  .ngx-pagination .pagination-next.disabled::after {
    font: normal normal normal 12px/1 FontAwesome;
    margin-top: 1px;
  }

  .ngx-pagination li.pagination-previous a,
  .ngx-pagination li.pagination-previous.disabled {
    padding-left: 20px !important;
  }
  .ngx-pagination .pagination-previous a::before,
  .ngx-pagination .pagination-previous.disabled::before {
    content: "\f053" !important;
    margin-left: 4px !important;
    font-size: 0.68rem;
  }

  .ngx-pagination li.pagination-next a,
  .ngx-pagination li.pagination-next.disabled {
    padding-right: 20px !important;
  }
  .ngx-pagination .pagination-next a::after,
  .ngx-pagination .pagination-next.disabled::after {
    content: "\f054" !important;
    margin-right: 4px !important;
    font-size: 0.68rem;
  }
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 10px;
}
.ng-select.ng-select-single{
  &.status-dropdown{
    height: 38px;
      min-height: 38px;
    .ng-select-container {
      height: 38px;
      min-height: 38px;
      font-size: 12px;
    }
  }
}


.dataTables_wrapper {
  .table {
    &-bordered {
      thead {
        tr {
          th {
            font-weight: bold !important;
            font-size: 12px !important;
          }
        }
      }
    }
  }
}

// Tooltip Css
.tooltip {
  min-width: 92px !important;
  &-left {
    min-width: 92px !important;
  }
}
.arrow-showhide {
  .tooltip {
    min-width: 140px !important;
    &-left {
      min-width: 140px !important;
    }
  }
}

.ngselect-input {
  .ng-select-container {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}
.ngselect-add-btn {
  height: 36px !important;
}

.clear-all {
  color: #3b3b3c;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    color: #dd4b39;
  }
}

.select-share-resource-worker {
  &.ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-optgroup {
        &.ng-option-disabled {
          .ng-option-label {
            color: #656565;
            font-weight: 600;
            padding: 8px 0 8px 8px;
            text-transform: uppercase;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.table-button {
  min-width: 77px;
}

.worker-assignment {
  display: flex;
  flex-direction: column;
  &-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.tooltip {
  &.left {
    .tooltip-arrow {
      border-top: 6px solid transparent !important;
      border-bottom: 10px solid transparent !important;
      border-left: 10px solid #222 !important;
      top: 9px !important;
    }
  }
}
.cel_arrowbg {
  padding: 0px !important;
  li {
    display: flex;
    a {
      display: flex;
      align-items: center;
    }
  }
}

.border-right-readis {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.menu-content {
  .mat-menu-content {
    .met-tablemenu {
      padding: 10px;
      line-height: 23px;
      height: 40px;
    }
  }
}

.mat-icon-button {
  width: 30px !important;
  height: 30px !important;
  i,
  .mat-icon {
    line-height: 15px !important;
  }
}

.application-task-name {
  position: relative;
  cursor: pointer;
  padding: 8px 3px;
  border: 1px solid transparent;
  border-radius: 4px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover,
  &:focus {
    cursor: pointer;
    padding: 8px 3px;
    border: 1px solid #2578a2;
    border-radius: 4px;
    background: transparent;
  }
  &.focus-visible {
    border: 1px solid #2578a2;
    padding: 8px 3px;
    border-radius: 4px;
    background: #fff !important;
  }
}
.task-inputfield {
  position: relative;
  display: flex;
  align-items: center;
  .check-icon {
    position: absolute;
    right: 7px;
  }
}

.panel-collapse-section {
  &:nth-of-type(2),
  &:nth-of-type(3),
  &:nth-of-type(4),
  &:nth-of-type(5),
  &:nth-of-type(6),
  &:nth-of-type(7),
  &:nth-of-type(8),
  &:nth-of-type(9),
  &:nth-of-type(10) {
    .calendar-date-view {
      .datepicker-theme {
        &.dp-popup {
          top: -260px !important;
        }
      }
    }
  }
}

.ngselect-overflow {
  .ng-select-container {
    .ng-value-container {
      .ng-placeholder {
        top: 10px !important;
        font-size: 13px;
      }
      .ng-value-label {
        font-size: 13px;
      }
    }
  }
}

.ng-dropdown-panel {
  &.ngselect-maplocation {
    .ng-dropdown-panel-items {
      width: 180px;
    }
  }
}

.ng-dropdown-panel {
  &.ngselect-maplocation {
    &.maplocation-dropdown{
      .ng-dropdown-panel-items {
        width: 140px !important;
      }
    }
  
  }
}

.ng-dropdown-panel {
  &.ngselect-maplocation {
    .ng-dropdown-panel-items {
      width: 100%;
      .ng-option {
        float: left;
        width: 158px;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 100%;
        text-align: left;
        word-wrap: break-word;
        white-space: nowrap;
      }
    }
  }
}

.button-reset {
  margin-top:5px;
  float: right;
  flex: 90px;
  min-width: 90px;
  flex-basis: 90px;
  flex-grow: 1;
  flex-shrink: 1;
}

.modal.fade .modal-dialog{
  &.dialog-center{
      transform: translate(0%,0%) !important;
} 
}

.error-massage, .redMark{
  font-size: 10px;
  color: #dd4b39;
  padding-top: 2px;
  padding-left: 1px;
}
.cell-align {
  padding: 0px !important;
  li {
    display: flex;
    a {
      display: flex;
      align-items: center;
    }
  }
}


// ::ng-deep .gm-style div[aria-hidden = "true"] {
//   background-color: #393939 !important;
//   color:red !important;
//   padding: 5px;
//   margin-left: 10px;
//   border-radius: 5px;
//   position: absolute;
//   top: 21px;;
// }


.agm-marker-label {
  position: absolute;
  top: 10px;
  left: 0px;
  font-size: 13px !important;
  font-weight: 400;
  color: #efe5e5 !important;
  transform: translate(-50%, 0px);

  &:before {
    position: absolute;
    content: " ";
    width: 0;
    height: 0px;
    border-width: 6px 5px 10px 5px; 
    border-color: transparent transparent #fabe4e91 transparent;
    border-style: solid;
    top: 8.4px;
    left: 47%;
  }
}

.si-wrapper-top{
  margin-top: -14px !important;
}


.deleted-user-status{
  color: #bd2e2ede !important;
  font-size: 13px !important;
  font-weight: 500;
}

.active-user-status{
  color: #2578a2 !important;
  font-size: 13px !important;
  font-weight: 500;
}

.drag-drop-element-wrapper {
  cursor: all-scroll;
}

.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    width: 87%; // you can add in px also
    height: 11%; // this line added
    margin-bottom: 0px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  
  .cdk-drag-placeholder {
    opacity: 0;
  }
  
  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
  
  .table-list-box:last-child {
    border: none;
  }
  
  .list-cdk-wrapper.cdk-drop-list-dragging
    .table-list-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

// .ng-select .ng-select-container .admin-user-status {
//   min-height: 20px;
// }

// .ng-select.ng-select-single .ng-select-container .admin-user-status {
//   height: 20px;
// }



.cdk-overlay-container {
  z-index: 10000 !important; /* Adjust as necessary */
}

.gm-style .gm-style-iw-c .gm-style-iw-d {
  overflow: hidden !important;
}

.gm-style .gm-style-iw-c {
  padding: 0 !important;
}


.mat-mdc-text-field-wrapper{
  border: 1px solid gray;
  height: 36px !important;
  border-radius: 3px !important;
  display: flex !important;
  align-items: center !important;
}

.mat-mdc-text-field-wrapper{
border-bottom: none !important;
}
.ng-select-container{
  background: #F9FBFE !important;
}

.mat-calendar-body-selected.mat-calendar-body-today,
.mat-calendar-body-selected
{
  background-color: #2578a2 !important;
  border-color: #2578a2;
}
.mat-datepicker-toggle-active {
  color: #2578a2 !important;
  border-color: #2578a2;
}

.mat-calendar-body-today {
  border-color: #2578a2 !important;
}

.mat-calendar-body-in-range::before {
  background-color: #bad4e0 !important;
  color: white !important;
}

.ng-dropdown-panel {
  z-index: 999 !important;
}

.ng-select.ng-select-opened .ng-select-container {
  z-index: 999 !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  position: static !important;
  padding-bottom: 0px !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: 0px !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  font-weight: 400 !important;
}

.ng-select div, .ng-select input, .ng-select span{
  font-weight: 400 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-left: 10px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: #fff;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
color: #000;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
  color: #000;
  background: #fff;
}

:host ::ng-deep .mat-calendar-body-cell-container:hover {
  background-color: #a4c8da !important;
}

.mat-focus-indicator:hover {
  background-color: #9ecbe2 !important;
  color: white;
}

.mat-calendar-body-disabled {
  cursor: not-allowed !important;
}

.mat-input-box{
  border-right: none;
  background-color: #fff !important;
}
// ::ng-deep .mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
//   display: none !important;
// }

 .mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  display: none !important;
}
 .mdc-checkbox__ripple {
  display: none !important;
}

